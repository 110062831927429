/* eslint-disable no-param-reassign */
import _ from 'lodash'
import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { slugify } from '../../../../helpers'
import './info-box-desktop-styles.scss'
import { Wrapper, ButtonMore } from '../../../../components'
import { device } from '../../../../theme/device'

const Text = ({ className, text }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: text }}></div>
)

const StyledText = styled(Text)`

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 22px;
  line-height: 36px;
  font-family: ${({ theme }) => theme.font.manrope};

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${device.tablet} { 
    text-align: left;
  }
  @media ${device.desktop} { 

    font-size: 26px;
    line-height: 46px;
  }
`

const InfoBoxDesktop = ({ information }) => {
  const { t } = useTranslation()
  const [offset, setOffset] = useState(0)
  const boxRef = useRef(null)
  const revealRef = useRef([])
  const breakpoints = useBreakpoint()
  revealRef.current = []
  const addToRefs = (el) => {
    if (el && !revealRef.current.includes(el)) {
      revealRef.current.push(el)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])

  useEffect(() => {
    const block = boxRef.current.offsetTop
    revealRef.current.forEach((el, index) => {
      if (breakpoints.xs) {
        if (el.offsetTop + block - 250 <= offset && block + el.offsetTop + el.offsetHeight / 3 > offset) {
          el.classList.add('active')
        } else {
          el.classList.remove('active')
        }
      } else if (el.offsetTop + block <= offset && block + el.offsetTop + (el.offsetHeight / 2) > offset) {
        el.classList.add('active')
      } else {
        el.classList.remove('active')
      }
      if ((el.offsetTop + block - 200) <= offset && block + el.offsetTop + (el.offsetHeight / 2) + 200 > offset) {
        el.classList.remove('hide')
      } else {
        el.classList.add('hide')
      }
    })
  }, [offset])

  return (
    <Wrapper
      ref={boxRef}
      style={{
        backgroundColor: '#FAFBFC',
        zIndex: 100,
      }}>

      <Wrapper
        paddingM="60px 25px"
        flexDirectionD="column"
        paddingD="0"
        widthD="1240px">
        {
          _.map(information, (info, idx) => {
            const string = info.content
            const stringWidthSpan = _.replace(string, slugify(info.highlight_word, ' '), `<span>${info.highlight_word}</span>`)
            return (

              <div
                ref={addToRefs}
                className="section-info"
                key={idx}>
                <Wrapper
                  className="section-big"
                  flexDirectionD="column"
                  alignItemsM="flex-start"
                >
                  <Wrapper
                    marginM="0 0 64px 0"
                    marginD="0 0 72px 0"
                    widthD="540px">
                    <StyledText text={stringWidthSpan } />
                  </Wrapper>
                  <ButtonMore
                    right
                    toright={'true'}
                    link={`/${info.links_to}`}
                  >{t(`home.buttons.${info.links_to}`)}
                  </ButtonMore >
                </Wrapper>
                <div className="section-img">
                  <div

                    className="image-box-info"
                    id={`section-${idx + 1}`}
                    style={{
                      backgroundImage: `url('${info.image.url}')`,
                    }}
                  >
                    {/* <img style={{ width: '100%' }} src={info.image.url} alt={info.image.alt}/> */}
                  </div>

                </div>
              </div>
            )
          })
        }

      </Wrapper>
    </Wrapper>
  )
}

export default InfoBoxDesktop

// start: '+=133 80%',
// end: '+=200 40%',
