import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import {
  Wrapper, H2, P,
} from '../../../../components'
import './testimonials.scss'
import Arrow from '../../../../assets/icons/TAM-icon-left-arrow.svg'
// import { testimonials } from '../../../../data/data'
import { useWindowDimensions } from '../../../../helpers'

const CircleStyled = styled.div`
  width: 180px;
  height: 180px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 109px;
  box-shadow: ${({ active }) => (active ? '0px 20px 50px 1px rgba(0,0,0,0.11)' : '')};
  z-index:  ${({ active }) => (active ? 100 : 0)};
  &:focus, &:focus-within, &:focus-visible {
    background: transparent;
    color: transparent;
  }
  img {
    width:  ${({ active }) => (active ? '100%' : '50%')};
    max-width: 148px;
  }
  `

const TextNumbers = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.gorditaM};
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
`

const Testimonials = ({ testimonials }) => {
  const [isActive, setIsActive] = useState(false)
  const [currentTestimonial, setCurrentTestimonial] = useState(testimonials[0])
  const size = useWindowDimensions()
  const buttonNext = useRef(null)
  const buttonPrev = useRef(null)

  const [activeIndex, setActiveIndex] = useState(0)

  // Used to determine which items appear above the active item
  const halfwayIndex = Math.ceil(testimonials.length / 2)

  // Usd to determine the height/spacing of each item
  const itemHeight = testimonials.length > 6 ? 140 : 180

  // Used to determine at what point an item is moved from the top to the bottom
  const shuffleThreshold = halfwayIndex * itemHeight

  // Used to determine which items should be visible. this prevents the "ghosting" animation
  const visibleStyleThreshold = shuffleThreshold / 2

  const determinePlacement = (itemIndex) => {
    // If these match, the item is active
    if (activeIndex === itemIndex) return 0
    // setToggle(false)
    if (itemIndex >= halfwayIndex) {
      // setToggle(true)
      if (activeIndex > itemIndex - halfwayIndex) {
        return (itemIndex - activeIndex) * itemHeight
      }
      return -(testimonials.length + activeIndex - itemIndex) * itemHeight
    }

    if (itemIndex > activeIndex) {
      // setToggle(true)
      return (itemIndex - activeIndex) * itemHeight
    }

    if (itemIndex < activeIndex) {
      // setToggle(true)
      if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
        return (testimonials.length - (activeIndex - itemIndex)) * itemHeight
      }
      return -(activeIndex - itemIndex) * itemHeight
    }
  }

  const onHandledActiveIndex = (it) => {
    setCurrentTestimonial(testimonials[it])
    setActiveIndex(it)
    if (it === activeIndex || it === activeIndex + 1 || it === activeIndex - 1 || it === testimonials.length) {
      // setIsActive(false)
      // setTimeout(() => {
      //   setIsActive(true)
      // }, 300)
    }
  }

  const onHandlePrev = (e) => {
    buttonPrev.current.focus({ preventScroll: true })
    e.preventDefault()
    if (activeIndex === 0) {
      onHandledActiveIndex(testimonials.length - 1)
    } else {
      onHandledActiveIndex(activeIndex - 1)
    }
  }

  const onHandleNext = (e) => {
    buttonNext.current.focus({ preventScroll: true })
    e.preventDefault()
    if (testimonials.length <= activeIndex + 1) {
      onHandledActiveIndex(0)
    } else {
      onHandledActiveIndex(activeIndex + 1)
    }
  }

  return (
    <Wrapper
      flexDirectionD="column"
      displayM="block"
      paddingD="160px 0">
      <Wrapper
        widthD="1920px"
        flexDirectionD="column"
      >

        <Wrapper
          widthD="1240px"
          style={{ minHeight: '650px' }}
        >
          <Wrapper
            flexDirectionD="column"
            style={{ flex: ' 1 1 60%' }}
          >
            <svg className="circle-box" height="650" width="650">
              <circle
                className={cn('circle', {
                  active: isActive,
                })}
                cx="325"
                cy="325"
                r="324"
                stroke="#E20613"
                strokeWidth="2"
                fillOpacity="0"
              />
              <circle
                cx="325"
                cy="325"
                r="323"
                stroke="#D7D9DD"
                strokeWidth="1"
                fillOpacity="0"
              />
            </svg>
            <Wrapper
              flexDirectionD="column"
              justifyContentD="space-between"
              heightD="430px"
              widthD="480px"
            >
              <Wrapper
                flexDirectionD="column"
              >
                <H2 color={'primary'}>{_.get(currentTestimonial, 'highlight')}</H2>
                <P
                  style={{ textAlign: 'center' }}
                  lineHeight="3xl"
                  lineHeightD="3xl"
                  marginD="16px 0 54px"
                >{_.get(currentTestimonial, 'description')}</P>
                <P style={{ textAlign: 'center' }}
                  lineHeight="base"
                  lineHeightD="base"
                  fontSizeD={'xs'}
                  marginD="0">{_.get(currentTestimonial, 'name')}</P>
                <P style={{ textAlign: 'center' }}
                  lineHeight="base"
                  lineHeightD="base"
                  fontSizeD="xs">{_.get(currentTestimonial, 'attestant_position')}</P>
              </Wrapper>
              <div className="testimonial-arrows-box">
                <button ref={buttonPrev} onClick={(e) => onHandlePrev(e)}> <Arrow width="20"/></button>
                <TextNumbers>  {activeIndex + 1}/{testimonials.length}</TextNumbers>
                <button ref={buttonNext} onClick={(e) => onHandleNext(e)}><Arrow width="20" /></button>
              </div>
            </Wrapper>
          </Wrapper>
          <div className="aside-testimonial">
            {
              _.map(testimonials, (test, i) => (
                <div
                  tabIndex={i}
                  key={i + test.name}
                  onClick={() => onHandledActiveIndex(i)}
                  className={cn('carousel-item-testimonial', {
                    active: activeIndex === i,
                    visible: Math.abs(determinePlacement(i)) <= visibleStyleThreshold,
                  })}
                  style={{
                    transform: `translateY(${determinePlacement(i)}px)`,
                  }}
                >
                  <CircleStyled
                    active={ activeIndex === i }
                  >
                    <img
                      src={test.logo.url}
                      alt={test.logo.alt}
                    />
                  </CircleStyled>
                </div>
              ))
            }
          </div>
        </Wrapper>

      </Wrapper>
    </Wrapper>

  )
}

export default Testimonials
