import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Wrapper, ButtonMore } from '../../../../components'
import { device } from '../../../../theme/device'
import { useWindowDimensions, slugify } from '../../../../helpers'

const Text = ({ className, text }) => (
  <div className={className} dangerouslySetInnerHTML={{ __html: text }}></div>
)

const StyledText = styled(Text)`

  color: ${({ theme }) => theme.colors.secondary};
  font-size: 22px;
  line-height: 36px;
  font-family: ${({ theme }) => theme.font.manrope};

  span {
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${device.desktop} { 

    font-size: 26px;
  line-height: 46px;
  }
`

const InfoBox = ({ info }) => {
  const string = info.content
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const { t } = useTranslation()
  const stringWidthSpan = _.replace(string, slugify(info.highlight_word, ' '), `<span>${info.highlight_word}</span>`)
  return (
    <Wrapper
      heightD="100vh"
      style={{ backgroundColor: '#FAFBFC' }}
    >
      <Wrapper
        paddingM="60px 25px"
        paddingD="0"
        widthD="1240px">
        {
          width <= 428 && (
            <Wrapper
              widthD="530px"
              heightD="530px"
              style={{
                borderRadius: '50%',
                overflow: 'hidden',
                marginBottom: '36px',
              }}
            >
              <img style={{ width: '100%' }} src={info.image.url} alt={info.image.alt}/>
            </Wrapper>

          )
        }
        <Wrapper
          flexDirectionD="column"
          alignItemsM="flex-start"
        >
          <Wrapper
            marginM="0 0 64px 0"
            marginD="0 0 72px 0"
            widthD="540px">
            <StyledText text={stringWidthSpan } />
          </Wrapper>
          <ButtonMore
            right
            toright={'true'}
            link={`/${info.links_to}`}
          >{t(`home.buttons.${info.links_to}`)}
          </ButtonMore >
        </Wrapper>
        {
          width > 428 && (
            <Wrapper
              widthD="530px"
              heightD="530px"
              widthM="350px"
              heightM="350px"
              style={{
                borderRadius: '50%',
                overflow: 'hidden',
                marginTop: '15px',
              }}
            >
              <img style={{ width: '100%' }} src={info.image.url} alt={info.image.alt}/>
            </Wrapper>

          )
        }
      </Wrapper>

    </Wrapper>

  )
}

export default InfoBox
