import React from 'react'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import video1 from '../../../../assets/videos/Cargo_Video.mp4'
import video2 from '../../../../assets/videos/Passenger_Video.mp4'

const VideoVox = ({ isInTheTop }) => (
  <MouseTooltip
    visible={true}
    offsetX={-200}
    offsetY={-200}
    style={{
      zIndex: -1,
    }}
  >
    <div className="mask-home">

      <video width="700" height="450" autoPlay muted loop src={!isInTheTop ? video2 : video1} type="video/mp4">
      </video>

    </div>
  </MouseTooltip>
)

export default VideoVox
