import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import cargoGift from '../../../../assets/videos/gifs/Cargo_375x375.gif'
import passengerGift from '../../../../assets/videos/gifs/Passenger_375x375.gif'
import { Wrapper } from '../../../../components'

const HeadMobile = () => {
  const [videoChange, setVideoChange] = useState(true)
  const [noPlacehoder, setNoPlacehoder] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      setVideoChange((prevState) => !prevState)
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNoPlacehoder(true)
    }, 10000)
    return () => clearTimeout(timeout)
  }, [])

  return (

    <Wrapper
      widthM="100%"
      heightM="auto"
      flexDirectionM="column"
      justifyContentM="flex-start"
      paddingM="20px"
    >
      <div className="mask-home-mobile">

        {
          videoChange ? (
            <div className={noPlacehoder ? 'placeholder-cargo noplacehonder' : 'placeholder-cargo'}>
              <div className="video" style={{
                backgroundImage: `url(${cargoGift})`,
              }}></div>

            </div>
          ) : (
            <div className={noPlacehoder ? 'placeholder-passenger noplacehonder' : 'placeholder-passenger'}>
              <div className="video" style={{
                backgroundImage: `url(${passengerGift})`,
              }}></div>

            </div>
          )
        }

      </div>
      <Wrapper
        widthM="100%"
        marginM="32px 0"
        heightM="auto"
        flexDirectionD="column"
        alignItemsM="flex-start"
        justifyContentM="flex-start"
        paddingM="5px"
        className="container-links-home-mobile"

      >
        <Link to="/what-we-offer/cargo/">
          <h2 className={videoChange ? 'title-links-home-mobile mark' : 'title-links-home-mobile'}>
            Cargo
          </h2>
        </Link>
        <Link to="/what-we-offer/passenger/">
          <h2 className={!videoChange ? 'title-links-home-mobile mark' : 'title-links-home-mobile'}>
            Passenger
          </h2>
        </Link>
      </Wrapper>
    </Wrapper>

  )
}

export default HeadMobile
