import _ from 'lodash'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  Wrapper,
} from '../../../../components'
import './testimonials-mobiles.scss'
import { device } from '../../../../theme/device'

const CircleStyled = styled.div`
  height: 160px;
  width: 160px;
  margin: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 109px;
  border: 1px solid #E6E6E6;
  img {
    width: 100%;
    max-width: 110px;
  }
  @media ${device.desktop} {
    box-shadow: 0px 10px 20px 1px rgba(0,0,0,0.11);
   
  }

`

const TestimonialsMobile = ({ testimonials }) => {
  const maxTestimonials = testimonials
  const refCarousel = useRef()
  const [width, setWith] = useState('0')

  const getWidth = (items, idx) => (idx * 100) / items.length
  // switch (true) {
  //   case idx === 2:
  //     return '66.666666%'
  //   case idx === 1:
  //     return '33.3333%'
  //   case idx === 0:
  //     return '0'
  //   default:
  //     break
  // }

  const settings = {
    dots: false,
    infinite: true,
    className: 'center',
    centerPadding: '60px',
    speed: 1000,
    // centerMode: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    fade: false,
    responsive: [
      {
        breakpoint: 764,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  }
  return (
    <div className="testimonials-mobile-container">
      <Slider
        afterChange={(e) => setWith(getWidth(maxTestimonials, e))}
        {...settings} ref={refCarousel}>
        {
          _.map(maxTestimonials, (testimonial) => (
            <Wrapper
              flexDirectionD="column"
              justifyContentM="flex-start"
              alignItemsM="flex-start"
            >

              <CircleStyled
              >
                <img src={testimonial.logo.url} alt={testimonial.logo.alt}/>
              </CircleStyled>

              <div className="testimonials-mobile-slider">
                <div>
                  <h3>{_.get(testimonial, 'highlight')}</h3>
                </div>
                <div>
                  <p>{_.get(testimonial, 'description')}</p>
                  <span>
                    {_.get(testimonial, 'name')}
                  </span>
                  <span>
                    {_.get(testimonial, 'attestant_position')}
                  </span>
                </div>
              </div>

            </Wrapper>
          ))}
      </Slider>
      <div className="testimonials-mobile-line">
        <span style={{
          marginLeft: `${width}%`,
          width: `${100 / testimonials.length}%`,
        }}> </span>
      </div>
    </div>

  )
}

export default TestimonialsMobile
