import React from 'react'
import {
  Wrapper, H2, Subtitle, Map, ButtonMore,
} from '../../../../components'

const MapHome = ({ title, t, width }) => (
  <Wrapper
    flexDirectionD="column"
    displayM="box"
    paddingM="120px 0"
    paddingD="80px 25px">
    <Wrapper
      widthD="1920px"
      flexDirectionD="column"
    >
      <Wrapper
        widthD="1240px"
        flexDirectionD="column"
      >
        <Subtitle>{t('home.map.network')}</Subtitle>
        <Wrapper
          style={{ textAlign: 'center' }}
          widthM="600px"
          widthD="600px"
          paddingM="0 15px"
          marginD="12px 0 74px 0">
          <H2
            fontSize="2xll"
            lineHeight="4xl"
            fontSizeD="4xl"
            lineHeightD="6xl">{title}</H2>
        </Wrapper>

      </Wrapper>
      <Map t={t} isHome/>
    </Wrapper>
    {width < 768
      && <ButtonMore
        style= {{
          marginLeft: '-30px',
        }}
        link="/connect-with-us"
        right
        toright={'true'}>
        {t('home.map.view')}
      </ButtonMore>}
  </Wrapper>

)

export default MapHome
