import _ from 'lodash'
import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import HomeView from './home-view'
import { usePartners } from '../../hooks/use-partners'
import { makeRequest, changelanguages } from '../../helpers'

const HomeController = () => {
  const [homeData, setHomeData] = useState([])
  const [statistics, setStatistics] = useState([{
    key: null,
    title: null,
    value: null,
  }])
  const { loading, partners, partnersHome } = usePartners()
  const [loadingHome, setLoading] = useState(true)
  const { t, i18n } = useTranslation()
  const getHomeData = useCallback(async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'pages',
      params: {
        lang: changelanguages(i18n.language),
        slug: 'home',
      },
    }).then((resp) => {
      setHomeData(resp.data[0])
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    getHomeData()
  }, [i18n.language])

  useEffect(() => {
    const st = _.get(homeData, 'acf.what_we_do.statistics')
    const objs = _.reduce(st, (result, value, key) => {
      (result).push({
        key,
        title: t(`home.statistics.${key}`),
        value,
      })
      return result
    }, [])
    setStatistics((prevState) => [[], ...objs])
  }, [homeData, i18n.language])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
  }, [])

  const viewProps = {
    loading,
    partners,
    partnersHome,
    loadingHome,
    data: homeData,
    statistics,
    t,
  }
  return (
    <HomeView {...viewProps} />
  )
}

export default HomeController
