import React, {
  useState, useEffect, useCallback, useRef,
} from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Wrapper } from '../../../../components'
import CargoFilled from '../../../../assets/icons/TAM_text_cargo_filled_home.svg'
import PassegersFillerd from '../../../../assets/icons/TAM_text_passenger_filled_home.svg'
import CargoOutLined from '../../../../assets/icons/TAM_text_cargo_outlined_home.svg'
import PassegersOutLined from '../../../../assets/icons/TAM_text_passenger_outlined_home.svg'
import VideoBox from '../video-box/video-vox'
import BigWord from '../../../../components/big-word/big-word'

const HeadHomeDesktop = () => {
  // const { height, width } = useWindowDimensions()
  const wrapperRef = useRef(null)
  const [isInTheTop, setIsInThetop] = useState(true)
  const [isVisible, setIsIsVisible] = useState(false)

  const [offset, setOffset] = useState(0)
  const onMouseMoveVideo = useCallback((e) => {
    const results = wrapperRef.current.getBoundingClientRect()
    if (e.screenY > 300 && e.screenY < 670) {
      setIsIsVisible(true)
      if (e.screenY < (results.height / 2) + 175) {
        setIsInThetop(true)
      } else {
        setIsInThetop(false)
      }
    } else {
      setIsIsVisible(false)
    }
  }, [offset])

  useEffect(() => {
    if (offset > 300) {
      setIsIsVisible(false)
    }
  }, [offset])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onMouseMoveVideo)
      return () => {
        window.removeEventListener('scroll', onMouseMoveVideo)
      }
    }
  })

  return (
    <Wrapper
      style={{
        overflow: 'hidden',
      }}
      paddingM="100px 40px"
      paddingD="137px 0 202px"
      onMouseMove={onMouseMoveVideo}
      flexDirectionD="column"
      ref={wrapperRef}
    >
      <Wrapper
        widthD="1240px"
        flexDirectionD="column"
        paddingD="0 15px"
      >

        <Wrapper
          justifyContentD="flex-start"
          marginD="0 0 35px 0"
        >
          <BigWord
            link="cargo"
            icon={CargoFilled }
            iconHover={CargoOutLined }
            widthD={'634px'}
            height={'137px'}
          />
        </Wrapper>

        <Wrapper
          justifyContentD="flex-start"
          paddingD="0 0 0 123px"
          paddingM="0 0 0 75px"
        >
          <BigWord
            link="passenger"
            icon={PassegersFillerd}
            iconHover={PassegersOutLined}
            widthD={'1004px'}
            height={'137px'}
          />
        </Wrapper>

        {isVisible && <VideoBox isInTheTop={isInTheTop} />}

      </Wrapper>

    </Wrapper>
  )
}

export default HeadHomeDesktop
