import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  BlockNumbers,
  Wrapper,
  FullImage,
  Seo,
  OurPartners,
  Spinner,
  VideoHome,
  ScrollSection,
} from '../../components'
import { useWindowDimensions } from '../../helpers'
import airplane from '../../assets/images/TAM_airplane_banner_1920.jpeg'
import {
  HeadHomeDesktop,
  InfoBox,
  InfoBoxDesktop,
  Testimonials,
  TestimonialsMobile,
  MapHome,
  HeadHomeMobile,
} from './components'
import './home-styles.scss'

// markup
const HomeView = ({
  statistics,
  partners,
  partnersHome,
  loading,
  loadingHome,
  data,
  t,
}) => {
  const { acf } = data
  const [showMap, setShowMap] = useState(false)
  const testimonials = _.get(acf, 'testimonials', undefined)
  const information = _.get(acf, 'text_image', undefined)
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)
  const breakpoints = useBreakpoint()
  useEffect(
    () => {
      const timer1 = setTimeout(() => setShowMap(true), 9000)
      return () => {
        clearTimeout(timer1)
      }
    }, [],
  )

  if (loadingHome) {
    return <Spinner loading={loading} />
  }
  // Client wants to take it aways
  // if (isBrowser && window.localStorage.getItem('isView')) {
  //   isVideoView = JSON.parse(window.localStorage.getItem('isView')) === true
  // }

  return (
    <React.Fragment>
      <Seo
        title={_.get(data, 'yoast_head_json.title')}
        description={_.get(data, 'yoast_head_json.description')}
        schema={_.get(data, 'yoast_head_json.schema')}
        type={_.get(data, 'yoast_head_json.og_type', {})}
      />

      <VideoHome />
      <Wrapper flexDirectionD="column">
        {width < 768 && <HeadHomeMobile />}
        {width >= 768 && <HeadHomeDesktop />}
        <BlockNumbers
          data={_.get(data, 'acf.what_we_do')}
          t={t}
          statistics={statistics}
          title={t('home.what')}
          breakpoints={breakpoints}

        />
        <FullImage image={airplane} />
        <OurPartners
          t={t}
          partners={partnersHome}
          title={t('home.ours')}
          porfolio />
        {!breakpoints.xs && <InfoBoxDesktop information={information} />}

        {
          breakpoints.xs && information && _.map(information, (info, idx) => (
            <InfoBox key={info.links_to + idx} info={info} />
          ))
        }

        {
          testimonials && (
            breakpoints.mdM
              ? <Testimonials testimonials={testimonials} />
              : <TestimonialsMobile testimonials={testimonials} />
          )
        }
        {
          showMap && (
            <MapHome
              width={width}
              title={_.get(acf, 'our_network_section.section_title')}
              t={t} />
          )
        }

      </Wrapper>
    </React.Fragment>
  )
}

export default HomeView
